@media (max-width: 400px) {
    .mobile-hide {
        display: none;
    }
}

.logo-container {
    justify-content: flex-end; 
    margin-right: 50px; 
  }
  
  .logo-img {
    width: 400px; 
    height: auto; 
  }