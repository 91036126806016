.intro-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    /* height: 100vh;
    width: 100vw; */
  }
  
  .intro-container {
    display: flex;
    align-items: center;
  }