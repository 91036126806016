.projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.project-card {
  background-color: #f8f8f8;
  color: #333;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-card h2 {
  margin-top: 0;
}

.project-card p {
  margin-bottom: 5px;
}

.project-card a {
  color: #804c75;
  text-decoration: none;
}

.project-card a:hover {
  text-decoration: underline;
}


.dark-mode .project-card {
  background-color: #4f4f4f;
  color: #ccc;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}