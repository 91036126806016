@import '~@fortawesome/fontawesome-free/css/all.css';

.App {
  text-align: center;
  transition: background-color .75s ease;
}

.light-mode {
  background-color: white; 
}

.dark-mode {
  background-color: #333; 
}

.text {
  flex: 1;
  padding: 20px; 
  color: #333; 
}

.dark-mode .text {
  color: #ccc;
}

.social-links {
  margin-top: 20px;
}

.social-link {
  margin-right: 20px; 
}

.social-link i {
  font-size: 24px; 
}

.nala-image {
  width: 250px; 
  height: auto; 
  border-radius: 60px;
}